{
  "name": "enrollhere-dialer-publisher",
  "version": "0.0.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "replace-timestamp": "node ./replace.build.js",
    "lint": "ng lint",
    "explore-source-maps": "source-map-explorer dist/**/*.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-dialer-publisher ./dist/enrollhere-dialer-publisher/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-dialer-publisher ./dist/enrollhere-dialer-publisher/browser"
  },
  "private": true,
  "dependencies": {
    "@angular-eslint/builder": "^18.4.0",
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.11",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.10",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "@angular/service-worker": "^18.2.10",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/pro-regular-svg-icons": "^6.6.0",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@ng-select/ng-select": "^13.9.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@sentry/angular": "^8.36.0",
    "@sentry/cli": "^2.38.1",
    "@swimlane/ngx-charts": "^20.5.0",
    "autoprefixer": "^10.4.20",
    "libphonenumber-js": "^1.11.12",
    "lottie-web": "^5.12.2",
    "moment-timezone": "^0.5.46",
    "ngx-cookie-service": "^18.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-toastr": "^19.0.0",
    "plotly.js": "^2.35.2",
    "plotly.js-dist-min": "^2.35.2",
    "prettier": "^3.3.3",
    "replace-in-file": "^8.2.0",
    "rxjs": "~7.8.1",
    "socket.io-client": "^4.8.1",
    "ssr-window": "^4.0.2",
    "tailwindcss": "^3.4.14",
    "tslib": "^2.8.1",
    "uuid": "^11.0.2",
    "write-excel-file": "^2.0.10",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular/cli": "~18.2.11",
    "@angular/compiler-cli": "^18.2.10",
    "@ngrx/schematics": "^18.1.1",
    "@types/jasmine": "~5.1.4",
    "@types/plotly.js-dist-min": "^2.3.4",
    "@types/uuid": "^10.0.0",
    "daisyui": "^4.12.14",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier-eslint": "^16.3.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.5.4"
  }
}
